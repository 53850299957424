import React, { FC, useState, useEffect } from 'react';
import api from '../../../../../api';
import { useTranslation } from 'react-i18next';
import { useNotify } from '../../../../../hooks/useNotifications';
import Dropdown from '../../../../../components/general/Dropdown';
import { NoteModal } from './NoteModal';
import { Item, OperatorNotesType, Notes } from '../types';
import { DateRange } from '../../../../../components/Dates/DateRange';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useData } from '../../../../../context/DataProvider';
import { ModeSwitch } from '../../../../reports/tickets/components/ModeSwitch';
import { AmlTable } from './AmlTable';
import { NotePreviewModal } from './NotePreviewModal';

interface Props {
	id: number;
	email: string;
}

export const AmlSection: FC<Props> = ({ id, email }) => {
	const { cityTimeZone } = useData();
	const [amlTags, setAmlTags] = useState([] as Item[]);
	const [selectedAmlTag, selectAmlTag] = useState('');
	const [noteModal, setNoteModal] = useState(false);
	const [excludeWithAmlTag, setExcludeWithAmlTag] = useState(false);
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const [timeZone, setTimeZone] = useState('Europe/Belgrade');
	const [backofficeUserEmail, setBackofficeUserEmail] = useState('');
	const [operatorNotes, setOperatorNotes] = useState<Notes[]>([]);
	const [note, setNote] = useState<null | string>(null);
	const [loaded, setLoaded] = useState(true);

	const { success, error } = useNotify();
	const { t } = useTranslation();

	const tagColor = (tag: string) => {
		switch (tag) {
			case 'TAG_1':
				return 'black';
			case 'TAG_2':
				return 'red';
			case 'TAG_3':
				return 'orange';
			case 'TAG_4':
				return 'yellow';
			case 'TAG_5':
				return 'purple';
			case 'TAG_6':
				return 'blue';
			case 'TAG_7':
				return 'pink';
			case 'TAG_8':
				return 'gray';
			case 'TAG_9':
				return 'green';
			case 'TAG_10':
				return 'beige';
		}
	};

	useEffect(() => {
		(async () => {
			try {
				setLoaded(false);
				const user = await api.SSO.getUser();
				setBackofficeUserEmail(user.email ?? '');

				const data = await api.users.getAmlTags();
				const tags = data.map((tag) => {
					const color = tagColor(tag);
					return {
						id: tag,
						name: tag.replace('_', ' '),
						color,
					};
				});

				tags.unshift({
					id: 'NO_TAG',
					name: 'NO TAG',
					color: 'white',
				});

				setAmlTags(tags);
			} catch (err: any) {
				const errorText =
					err?.response?.data.detail?.[0]?.msg ||
					err?.response?.data?.detail ||
					t('toast.error.verificationLoadError');
				error(errorText);
			} finally {
				setLoaded(true);
			}
		})();
		getOperatorNotes();
	}, []);

	const changeAmlTag = async () => {
		try {
			setLoaded(false);
			const tag = selectedAmlTag === 'NO_TAG' ? null : selectedAmlTag;

			const data = await api.users.updateUsersAmlTag(id, tag);
			success(t('toast.success.amlTagUpdated'));
		} catch (err: any) {
			const errorText =
				err?.response?.data.detail?.[0]?.msg ||
				err?.response?.data?.detail ||
				t('toast.error.verificationLoadError');
			error(errorText);
		} finally {
			setLoaded(true);
		}
	};

	const getOperatorNotes = async () => {
		setLoaded(false);
		const selectedTagName = amlTags.find((tag) => tag.id === selectedAmlTag ?? '');

		let dtFrom = null;
		let dtTo = null;

		if (startDate) {
			const _dtFrom = zonedTimeToUtc(startDate, timeZone);
			dtFrom = _dtFrom ? new Date(_dtFrom.setHours(0, 0, 0, 0)).toISOString() : null;
		}

		if (endDate) {
			const _dtTo = zonedTimeToUtc(endDate, timeZone);
			dtTo = _dtTo ? new Date(_dtTo.setHours(23, 59, 59, 999)).toISOString() : null;
		}

		const _selectedTagName = selectedTagName?.name.replace(' ', '_') || '';
		const amlTag = _selectedTagName === 'NO_TAG' ? null : _selectedTagName;

		const params: OperatorNotesType = {
			idUser: id,
			dtFrom,
			dtTo,
			amlTag,
			backofficeUserEmail,
			excludeWithAmlTag,
		};

		try {
			const data = await api.users.getOperatorNote(params);
			setOperatorNotes(data);
		} catch (err: any) {
			const errorText =
				err?.response?.data.detail?.[0]?.msg ||
				err?.response?.data?.detail ||
				t('toast.error.verificationLoadError');
			error(errorText);
		} finally {
			setLoaded(true);
		}
	};

	useEffect(() => {
		if (selectedAmlTag === '') return;
		changeAmlTag();
	}, [selectedAmlTag]);

	return (
		<>
			<div className={`align_center flex_justify_space_between col-12 padding_20 ${!loaded ? 'disabled' : ''}`}>
				<div className=" align_center flex_justify_start">
					<div className="margin_right_10 font_12px font_400">{t('usersAdministrations.amlTag')}:</div>
					<Dropdown
						items={amlTags}
						selectedItem={selectedAmlTag}
						selectItem={selectAmlTag}
						listClass={'left_0'}
						icon={'mdi mdi-menu-down'}
						margins={['0', '5', '0', '0']}
						setItemColor={true}
						fontSize={'10px'}
					/>

					<button
						className="btn sm red outline margin_right_10"
						onClick={() => {
							selectAmlTag('');
						}}
					>
						{t('general.clear')}
					</button>

					<Dropdown
						items={cityTimeZone.map(({ id, name }) => ({ id: `${id}`, name }))}
						selectItem={setTimeZone}
						selectedItem={timeZone}
						margins={['0', '0', '0', '4']}
						icon={'mdi mdi-menu-down'}
						withSearch
					/>

					<DateRange
						endDate={endDate}
						startDate={startDate}
						maxDate={new Date()}
						setEndDate={setEndDate}
						setStartDate={setStartDate}
						datePickerClassName={'min_width_50'}
					/>

					<ModeSwitch
						checked={excludeWithAmlTag}
						toggle={() => setExcludeWithAmlTag((prev) => !prev)}
						leftLabel={''}
						rightLabel={t('usersAdministrations.withoutTag')}
					/>
				</div>
				<div>
					<button className="btn lg blue" onClick={getOperatorNotes}>
						{t('general.filter')}
					</button>
					<button className="btn lg blue outline" onClick={() => setNoteModal(true)}>
						{t('usersAdministrations.addNote')}
					</button>
				</div>
			</div>
			<div></div>
			{operatorNotes && (
				<div className={`padding_10  ${!loaded ? 'disabled' : ''}`}>
					<AmlTable operatorNotes={operatorNotes} setNote={setNote} />
				</div>
			)}

			{noteModal && <NoteModal id={id} setNoteModal={setNoteModal} getOperatorNotes={getOperatorNotes} />}
			{note && <NotePreviewModal setNote={setNote} descriptionValue={note} />}
		</>
	);
};
