import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDCard, Passport } from '../types';
import { DateField } from './DateField';
import { DocumentSelect } from './DocumentSelect';
import { RadioGroup } from './RadioGroup';
import { TextField } from './TextField';
import { TimeField } from './TimeField';
import { genderOptions } from '../utils';
import { ChangeUserBaseLocation } from './ChangeUserBaseLocation';
import { ChangeUserStatus } from './ChangeUserStatus';
import { SwitchField } from './SwitchField';
import { SwitchFieldSmall } from './SwitchFieldSmall';
import { DescriptionField } from './DescriptionField';
import { TextFieldWithButton } from './TextFieldWithButton';
import { EditEmailModal } from './EditEmailModal';
import { useUser } from '../../../../../context/UserProvider';
import { useFormContext } from 'react-hook-form';
import api from '../../../../../api';
import NotificationModal from '../../../../../components/general/NotificationModal';
import Delete from '../../../../../utils/svg/Delete';
import { useNotify } from '../../../../../hooks/useNotifications';
import { useOnlineUser } from '../../../context/OnlineUserContext';
import { DateValueFiled } from './DateValueFiled';
import { TextDateField } from './TextDateField';

interface Props {
	edit: boolean;
	documentType: IDCard | Passport;
	setDocumentType: (type: IDCard | Passport) => void;
	isInvalidID?: boolean;
}

export const SelfBannedTo: FC<{ label: string; displaySymbol: boolean }> = ({ label, displaySymbol }) => {
	if (displaySymbol) {
		return (
			<div className="form_row read_only">
				<div className="col-6">
					<p style={{ fontSize: '22px', padding: '0 8px' }}>&#8734;</p>
				</div>
			</div>
		);
	}

	return <DateValueFiled name={'dtSelfBannedTo'} label={label} displayTime={false} />;
};

export const UserInfo: FC<Props> = ({ edit, documentType, setDocumentType, isInvalidID }) => {
	const [showSelfBanModal, setShowSelfBanModal] = useState(false);
	const [updateSelfBanLoader, setUpdateSelfBanLoader] = useState(false);

	const { success, warn } = useNotify();
	const { setOnlineUser } = useOnlineUser();

	const { t } = useTranslation();

	const { getValues, setValue, watch } = useFormContext();

	const selfBanned = getValues('selfBanned');
	const selfBannedTo = getValues('dtSelfBannedTo');
	const userUid = getValues('uid');

	const { isNotPolitician } = watch();

	useEffect(() => {
		if (isNotPolitician) setValue('dtPoliticallyActiveUntil', null);
	}, [isNotPolitician, setValue]);

	const openSelfBanModal = () => {
		setShowSelfBanModal(true);
	};

	const updateSelfBannedUserData = async () => {
		setUpdateSelfBanLoader(true);

		try {
			const resUserData = await api.users.updateSelfBanned(userUid, selfBanned);

			setOnlineUser(resUserData);

			// Reset date values
			setValue('dtSelfBannedFrom', null);
			setValue('dtSelfBannedTo', null);

			success(t('toast.success.updateSuccessful', { entity: t('usersAdministrations.selfBanned') }));
		} catch (error) {
			setValue('selfBanned', !selfBanned);
			warn(t('toast.warn.updateProblem', { entity: t('usersAdministrations.selfBanned') }));
		}

		setUpdateSelfBanLoader(false);
		setShowSelfBanModal(false);
	};

	const closeBannedUserModal = () => {
		setValue('selfBanned', !selfBanned);
		setShowSelfBanModal(false);
	};

	const [editMailModal, setEditEmailModal] = useState(false);

	const [newEmail, setNewEmail] = useState('');

	const closeEditEmailModal = () => {
		setNewEmail('');
		setEditEmailModal(false);
	};
	const openEditEmailModal = () => {
		setEditEmailModal(true);
	};
	const { roles } = useUser();
	const [emailChangeRole] = useState(roles && roles['support'].includes('USER_EMAIL_CHANGE'));

	const [politicianChangeRole] = useState(roles && roles['support'].includes('USER_POLITICALLY_ACTIVE_UPDATE'));

	return (
		<>
			<div className="flex_direction_row padding_30">
				<div className="col-4 padding_30 margin_left_auto margin_right_auto">
					<TextField
						name={'username'}
						label={`${t('usersAdministrations.userName')}`}
						editable={false}
						edit={edit}
					/>
					<TextFieldWithButton
						name={'email'}
						label={`${t('usersAdministrations.email')}`}
						editable={false}
						edit={edit}
						openEditEmailModal={openEditEmailModal}
						showBtn={emailChangeRole}
						newEmail={newEmail}
					/>
					<TextField name={'code'} label={`${t('usersAdministrations.code')}`} editable={false} edit={edit} />
					<TextField name={'id'} label={`${t('usersAdministrations.userId')}`} editable={false} edit={edit} />
					<TextField name={'firstName'} label={`${t('usersAdministrations.name')}`} editable={true} edit={edit} />
					<TextField
						name={'lastName'}
						label={`${t('usersAdministrations.lastName')}`}
						editable={true}
						edit={edit}
					/>
					<TextField
						name={'nickname'}
						label={`${t('usersAdministrations.nickname')}`}
						editable={true}
						edit={edit}
					/>
					{/* <TextField name={'nickname'} label={`${t('usersAdministrations.nickname')}`} editable={true} edit={edit} /> */}

					<ChangeUserStatus edit={edit} />
					<ChangeUserBaseLocation edit={edit} />

					<TextDateField
						nameText={'registrationIp'}
						nameDate={'created'}
						label={`${t('usersAdministrations.registrationDateIp')}`}
						editable={false}
						edit={edit}
					/>

					<TimeField name={'dtVerified'} label={`${t('usersAdministrations.activationDate')}`} />

					<TextDateField
						nameText={'lastIpAddress'}
						nameDate={'lastActive'}
						label={`${t('usersAdministrations.lastActiveIp')}`}
						editable={false}
						edit={edit}
					/>

					<div className="col_border_bottom col_border_top">
						<TextDateField
							nameText={'accountVerifiedBy'}
							nameDate={'dtAccountVerified'}
							label={`${t('usersAdministrations.verificationTime_DateOperator')}`}
							editable={false}
							edit={edit}
						/>
						<SwitchField
							name={'hasVerificationData'}
							label={`${t('usersAdministrations.hasVerificationData')}`}
							onLabel={`${t('general.yes')}`}
							offLabel={`${t('general.no')}`}
							editable={false}
							edit={edit}
						/>
					</div>
				</div>

				<div className="col-4 padding_30 margin_left_auto margin_right_auto">
					<TextField
						name={'nationalId'}
						label={`${t('usersAdministrations.nationalId')}`}
						editable={true}
						edit={edit}
						// isInvalidID={isInvalidID}
					/>

					<TextField
						name={'country'}
						label={`${t('usersAdministrations.nationality')}`}
						editable={true}
						edit={edit}
					/>

					<DocumentSelect edit={edit} documentType={documentType} setDocumentType={setDocumentType} />

					<TextField
						name={'documentIssuer'}
						label={`${t('usersAdministrations.documentIssuer')}`}
						editable={true}
						edit={edit}
					/>

					<DateField
						name={'documentExpirationDate'}
						label={`${t('usersAdministrations.documentExpirationDate')}`}
						editable={true}
						edit={edit}
					/>

					<DateField
						name={'birthDate'}
						label={`${t('usersAdministrations.birthDate')}`}
						editable={true}
						edit={edit}
						// isInvalidFormat={isInvalidID}
					/>
					<TextField
						name={'placeOfBirth'}
						label={`${t('usersAdministrations.placeOfBirth')}`}
						editable={true}
						edit={edit}
					/>

					<RadioGroup
						name={'gender'}
						label={`${t('usersAdministrations.gender')}`}
						editable={true}
						edit={edit}
						options={genderOptions}
					/>
					<TextField
						name={'telephone'}
						label={`${t('usersAdministrations.phoneNumber')}`}
						editable={true}
						edit={edit}
					/>
					<TextField name={'address'} label={`${t('usersAdministrations.address')}`} editable={true} edit={edit} />

					<TextField name={'city'} label={`${t('usersAdministrations.city')}`} editable={true} edit={edit} />

					<TextField name={'zip'} label={`${t('usersAdministrations.zip')}`} editable={true} edit={edit} />

					<TextField
						name={'operator'}
						label={`${t('usersAdministrations.operator')}`}
						editable={false}
						edit={edit}
					/>
					<TextField name={'uid'} label="UUID" editable={false} edit={edit} />
					<TextField
						name="bankAccount"
						label={`${t('usersAdministrations.bankAccount')}`}
						editable={true}
						edit={edit}
					/>
				</div>
				<div className="col-4 padding_30 margin_left_auto margin_right_auto">
					<div className="flex_center form_row ">
						<label className="input_label font_600 col-2" style={{ color: '#3f4750' }}>{`${t(
							'usersAdministrations.newsAndPromo'
						)}`}</label>
						<div className="col-10 flex_center">
							<SwitchFieldSmall
								name={'smsPromotions'}
								label={`${t('usersAdministrations.sms')}`}
								onLabel={`${t('general.active')}`}
								offLabel={`${t('general.disabled')}`}
								editable={edit}
								edit={edit}
							/>
							<SwitchFieldSmall
								name={'emailPromotions'}
								label={`${t('usersAdministrations.email')}`}
								onLabel={`${t('general.active')}`}
								offLabel={`${t('general.disabled')}`}
								editable={edit}
								edit={edit}
							/>
							<SwitchFieldSmall
								name={'pushPromotions'}
								label={`${t('usersAdministrations.promo')}`}
								onLabel={`${t('general.active')}`}
								offLabel={`${t('general.disabled')}`}
								editable={edit}
								edit={edit}
							/>
							<SwitchFieldSmall
								name={'sitePromotions'}
								label={`${t('usersAdministrations.site')}`}
								onLabel={`${t('general.active')}`}
								offLabel={`${t('general.disabled')}`}
								editable={edit}
								edit={edit}
							/>
							<SwitchFieldSmall
								name={'callPromotions'}
								label={`${t('usersAdministrations.phoneCall')}`}
								onLabel={`${t('general.active')}`}
								offLabel={`${t('general.disabled')}`}
								editable={edit}
								edit={edit}
							/>
						</div>
					</div>
					<div className="col_border_bottom col_border_top">
						<SwitchField
							name={'isNotPolitician'}
							label={`${t('usersAdministrations.notPolitician')}`}
							onLabel={`${t('general.active')}`}
							offLabel={`${t('general.disabled')}`}
							editable={edit && politicianChangeRole}
							edit={edit}
						/>
						<DateField
							name={'dtPoliticallyActiveUntil'}
							label={`${t('usersAdministrations.politicallyExpirationDate')}`}
							editable={edit && !isNotPolitician && politicianChangeRole}
							edit={edit}
							isInvalidFormat={isInvalidID}
						/>
					</div>
					<div className="col_border_bottom col_border_top">
						<SwitchField
							name={'selfBanned'}
							label={`${t('usersAdministrations.selfBanned')}`}
							onLabel={`${t('general.active')}`}
							offLabel={`${t('general.disabled')}`}
							editable={selfBanned}
							edit={true}
							onChange={openSelfBanModal}
						/>
						<div className="flex_center">
							<DateValueFiled
								name={'dtSelfBannedFrom'}
								label={`${t('usersAdministrations.selfBannedFromTo')}`}
								displayTime={true}
							/>
							<SelfBannedTo label={''} displaySymbol={selfBanned && !selfBannedTo} />
						</div>
					</div>

					{/* selfBanned notification modal*/}
					<NotificationModal
						opened={showSelfBanModal}
						cancelText={t('general.cancel')}
						confirmText={t('general.confirm')}
						cancelAction={closeBannedUserModal}
						confirmAction={updateSelfBannedUserData}
						loading={updateSelfBanLoader}
					>
						<div className="context_icon margin_top_40">
							<Delete />
						</div>

						<h2 data-testid="modal-msg" className="modal_message_title">
							{t('Are you sure ?')}
						</h2>
					</NotificationModal>

					<DescriptionField
						name={'comment'}
						label={`${t('usersAdministrations.comment')}`}
						editable={true}
						edit={edit}
					/>
				</div>
			</div>
			{editMailModal ? (
				<EditEmailModal close={closeEditEmailModal} setNewEmail={setNewEmail} newEmail={newEmail} />
			) : null}
		</>
	);
};
