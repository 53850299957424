import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/Modals';

interface Props {
	setNote: React.Dispatch<React.SetStateAction<string | null>>;
	descriptionValue: string;
}

export const NotePreviewModal: FC<Props> = ({ setNote, descriptionValue }) => {
	const { t } = useTranslation();

	return (
		<Modal
			opened={true}
			size={'md'}
			align={'start'}
			direction={'column'}
			cancelAction={() => {
				setNote(null);
			}}
			header={<h2 className="modal_title padding_left_20">{`${t('general.note')}`}</h2>}
			body={
				<div>
					<div className="col-12 align_center padding_top_30 flex_column padding_bottom_30">
						<div className="flex_direction_row col-10 margin_top_10 align_center">
							<p>{descriptionValue}</p>
						</div>
					</div>
				</div>
			}
		></Modal>
	);
};
