import React, { FC, useEffect, useMemo } from 'react';
import { useTicket } from '../../context/TicketContext';
import { formatDate } from '../../../../../utils/getDate';
import { useData } from '../../../../../context/DataProvider';
import { TicketEvent } from '../../../../../pages/risk-management/controller/types';
import { toFixed } from '../../../../../utils/toFixed';

import { FullOdd, Result } from '../../types';
import { ACTIVE } from '../../../../../consts/strings';
import { useTranslation } from 'react-i18next';
import { oddsExtrasReplacer } from '../../../../../utils/oddsExtrasReplacer';

interface Props {
	event: TicketEvent;
	systemIndex: number;
	eventIndex: number;
	systemsOriginal: { events: TicketEvent[] | []; required: number[] }[];
}
export const EventRowDiff: FC<Props> = ({ event, systemIndex, eventIndex, systemsOriginal }) => {
	const { t } = useTranslation();
	const { marketsMap } = useData();
	const { eventsAdditionalData, events, ticket, similarTicket, showSimilarTicket } = useTicket();
	const { id, list_code, live, utc_scheduled, sport, competitors, odds, similar } = event;

	const [date, time] = formatDate(utc_scheduled, 'date.month.', 'hours:minutes');
	const [{ name: home }, { name: away } = { name: '' }] = competitors || [{}, {}];

	useEffect(() => {
		showSimilarTicket(false);
	}, []);

	const extractData = (odd: string) => {
		const [splited] = odd.split('|');
		const marketId = odd.split(':', 2).join(':');
		const marketName = marketsMap[sport.id]?.markets?.markets[marketId]?.name || '';
		const oddtype = marketsMap[sport.id]?.markets?.markets[marketId]?.oddtypes[splited] || {};

		const currentOddType =
			eventsAdditionalData?.[id]?.[oddtype.idMappedOddtype] || eventsAdditionalData?.[id]?.[oddtype.id];

		const currentValue =
			currentOddType &&
			currentOddType.oddtype_status === 1 &&
			currentOddType.market_status === ACTIVE &&
			(currentOddType.manual_status === null || currentOddType.manual_status === ACTIVE)
				? currentOddType.value
				: null;
		return { marketId, oddtype, marketName, currentValue };
	};

	const systemsAuth = ticket.systems.map(({ events, required }) => ({
		required,
		events: events.map((event) => {
			const similar = similarTicket?.systems
				.map(({ events }) => events)
				.flat()
				.find((flatEvent) => event.id === flatEvent.id);
			return {
				...event,
				similar,
			};
		}),
	}));

	const eventId = event.id;

	let isRemoved = false;

	let eventAuth = {} as TicketEvent;

	const findSystemIndex = () => {
		let systemIndex = 0;
		for (let i = 0; i < systemsAuth.length; i++) {
			const isEventInSystem = systemsAuth[i].events.some((event) => event.id === eventId);

			if (isEventInSystem) {
				systemIndex = i;
				break;
			}
		}

		return systemIndex;
	};

	const _systemIndex = systemsOriginal.length === systemsAuth.length ? systemIndex : findSystemIndex();

	for (const systemEvent of systemsAuth[_systemIndex]?.events) {
		if (systemEvent.id === eventId) {
			eventAuth = systemEvent;
			break;
		}
	}
	if (Object.keys(eventAuth).length === 0) {
		isRemoved = true;
	}

	const fullOdds: FullOdd[] = useMemo(() => {
		return Object.keys(odds).map((odd) => {
			const { oddtype, marketName, marketId, currentValue } = extractData(odd);
			const similarValue: number | null = event.similar?.odds?.[odd]?.value || null;
			return {
				...odds[odd],
				...oddtype,
				id: odd,
				marketName,
				marketId,
				eventId: event.id,
				systemIndex,
				eventIndex,
				currentValue,
				similarValue,
			};
		});
	}, [odds, eventsAdditionalData, similar]);

	return (
		<div className={`table_row ${isRemoved ? 'bg_row_color_red' : ''}`} style={{ border: '1px solid red' }}>
			<div className="cell ticket_code">{list_code}</div>
			<div className="cell icon_single_col">
				{live ? (
					<svg className="svg_icon live">
						<use xlinkHref="/assets/svg/sprite_mb.svg#live_ico"></use>
					</svg>
				) : null}
			</div>
			<div className="cell ticket_event_timestamp_col">
				<div className="timestamp">
					<span className="period">{date}</span>
					<div className="divider"></div>
					<span className="period">{time}</span>
				</div>
			</div>
			<div className="cell sport_icon">
				<div className="sport tooltip_left">
					<svg className="e_ico">
						<use xlinkHref={`/assets/svg/sprite_sport_icons.svg#${sport.id}`}></use>
					</svg>
					<span className="tooltip_content">{sport.name}</span>
				</div>
			</div>
			<div className="cell competetors_col">
				<div className="competetors vert">
					<span className="team_home">{home}</span>
					{away ? (
						<>
							<span className="separator"></span>
							<span className="team_away">{away}</span>
						</>
					) : null}
				</div>
			</div>
			<div className="cell result_col"></div>

			<div className="cell type_long">
				{fullOdds.map((odd, i) => (
					<div key={`${systemIndex}:${eventIndex}:${odd.marketName}:${i}`} className="item_wrap">
						<span>{`${oddsExtrasReplacer(odd.marketName, odd.extras)}`} </span>
						<span>{`${odd.extras?.boundary ?? odd.special_value ? ` | ` : ''}`}</span>
						<span
							className={
								!isRemoved
									? ` ${
											eventAuth?.odds[odd.id]?.extras?.boundary ??
											eventAuth?.odds[odd.id]?.special_value <
												Number(odd.extras?.boundary ?? odd.special_value)
												? 'red_text'
												: eventAuth?.odds[odd.id]?.extras?.boundary ??
												  eventAuth?.odds[odd.id]?.special_value >
														Number(odd.extras?.boundary ?? odd.special_value)
												? 'green_text'
												: ''
									  }`
									: ''
							}
						>
							{!isRemoved &&
								`${
									eventAuth?.odds[odd.id]?.special_value
										? `${eventAuth?.odds[odd.id].extras?.boundary ?? eventAuth?.odds[odd.id]?.special_value}`
										: ' '
								}`}
						</span>
						<span>{`${odd.special_value ? `| ${odd.extras?.boundary ?? odd.special_value}` : ''}`}</span>
					</div>
				))}
			</div>

			<div className="cell type_short">
				{fullOdds.map(({ name, description, bonus_type, extras }, i) => {
					const namePreview = bonus_type
						? `B${oddsExtrasReplacer(name, extras)}`
						: oddsExtrasReplacer(name, extras);
					return (
						<div
							key={`${systemIndex}:${eventIndex}:${name}:${i}`}
							className="item_wrap"
							title={oddsExtrasReplacer(description, extras)}
						>
							<strong>{namePreview}</strong>
						</div>
					);
				})}
			</div>
			<div className="cell icon_single_col"></div>
			{fullOdds.map(({ voided }, index) =>
				voided ? (
					<i className="mdi mdi-backup-restore tooltip_left" key={index}>
						<span className="tooltip_content">{t('live.voidedOdd')}</span>
					</i>
				) : null
			)}
			<div className="cell stake row" style={{ maxWidth: 160 }}>
				{fullOdds.map((odd, i) => {
					return (
						<div key={`${systemIndex}:${eventIndex}:${odd.value}:${i}`} className="item_wrap">
							{!isRemoved ? (
								<button
									className={`stake_btn ${
										toFixed(eventAuth.odds[odd.id]?.value) < toFixed(odd.value)
											? 'red'
											: toFixed(eventAuth.odds[odd.id]?.value) > toFixed(odd.value)
											? 'green'
											: ''
									}`}
								>
									{eventAuth?.odds[odd.id] ? toFixed(eventAuth?.odds[odd.id].value) : ' - '}
								</button>
							) : (
								<button className={`stake_btn `}> - </button>
							)}

							<span>{` | `}</span>

							<button className={`stake_btn disabled`}>{odd?.voided ? `1.00` : toFixed(odd.value)}</button>
						</div>
					);
				})}
			</div>
		</div>
	);
};
