import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Notes } from '../types';
import getDate from '../../../../../utils/getDate';

interface Props {
	operatorNotes: Notes[];
	setNote: React.Dispatch<React.SetStateAction<string | null>>;
}

export const AmlTable: FC<Props> = ({ operatorNotes, setNote }) => {
	return (
		<>
			<table className="admin_table sticky_header full_w">
				<TableHeader />
				<tbody className="t_body">
					{operatorNotes?.map((operatorNote) => (
						<TableRow key={operatorNote.dtCreated} operatorNote={operatorNote} setNote={setNote} />
					))}
				</tbody>
			</table>
		</>
	);
};

const TableHeader: FC = () => {
	const { t } = useTranslation();

	return (
		<thead className="t_head">
			<tr data-testid="head-row">
				<th>{t('usersAdministrations.amlTag')}</th>
				<th>{t('general.dateCreated')}</th>
				<th>{t('usersAdministrations.BackofficeUserEmail')}</th>
				<th>{t('general.note')}</th>
			</tr>
		</thead>
	);
};

interface RowProps {
	operatorNote: Notes;
	setNote: React.Dispatch<React.SetStateAction<string | null>>;
}

const TableRow: FC<RowProps> = ({ operatorNote, setNote }) => {
	const { t } = useTranslation();
	const { amlTag, dtCreated, email, note } = operatorNote;
	const [dateCreated, timeCreated] = getDate(dtCreated).split(' ');

	const openNote = () => {
		setNote(note);
	};
	return (
		<tr data-testid="body-row">
			<td>{amlTag}</td>
			<td>
				<span className="date font_700">{dateCreated}</span>
				<span className="time"> {timeCreated}</span>
			</td>
			<td>{email}</td>
			<td>
				<span className="tooltip_left">
					<i className="mdi mdi-eye" onClick={openNote}></i>
					<span className="tooltip_content">{t('usersAdministrations.viewNote')}</span>
				</span>
			</td>
		</tr>
	);
};
