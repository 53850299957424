import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { formatDate } from '../../../../../utils/getDate';

interface Props {
	nameText: string;
	nameDate: string;
	label: string;
	editable: boolean;
	edit: boolean;
	isInvalidID?: boolean;
}

export const TextDateField: FC<Props> = ({ nameText, nameDate, label, editable, edit, isInvalidID }) => {
	const { register, getValues } = useFormContext();

	const raw = getValues(nameDate);
	const valueDate = raw ? formatDate(raw, 'date.month.year hours:minutes')[0] : '';

	return (
		<div className={`form_row ${!(editable && edit) ? 'read_only' : ''} `}>
			<label htmlFor={nameText} className="input_label col-3">
				{label}
			</label>
			<div className="col-9 flex_center">
				<input
					type="text"
					id={nameDate}
					className="normal focus full_w"
					name={nameDate}
					value={valueDate}
					onChange={() => {}}
					disabled={true}
				/>

				<input
					type="text"
					id={nameText}
					className={`normal focus full_w ${isInvalidID ? 'red_hot_border' : ''}`}
					name={nameText}
					ref={register}
					disabled={!editable || !edit}
				/>
			</div>
		</div>
	);
};
