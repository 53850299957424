import React, { FC } from 'react';
import { VerificationData } from '../types';
import Modal from '../../../../../components/Modals';
import { useTranslation } from 'react-i18next';
import getDate from '../../../../../utils/getDate';

interface Props {
	setDocumentsModal: React.Dispatch<React.SetStateAction<boolean>>;
	accountVerifiedBy: string;
	dtAccountVerified: string;
	verificationData: VerificationData | null;
	isIdVerification: boolean;
}

const DocumentsModal: FC<Props> = ({
	setDocumentsModal,
	accountVerifiedBy,
	dtAccountVerified,
	verificationData,
	isIdVerification,
}) => {
	const { t } = useTranslation();
	const frontIdImg = verificationData?.idImageData ?? null;
	const backIdImg = verificationData?.idBackImageData ?? null;

	const close = () => setDocumentsModal(false);

	const [date, time] = getDate(dtAccountVerified).split(' ');

	return (
		<Modal
			opened={true}
			size={'lg'}
			align={'center'}
			direction={'column'}
			cancelAction={close}
			body={
				<div className="col-12 align_start padding_20 flex_column">
					<div className="flex_direction_row col-8 margin_top_10 align_center"></div>
					{frontIdImg && (
						<div
							className="col-11 justify_center align_center margin_top_30 margin_bottom_10"
							style={{ height: '260px' }}
						>
							<img style={{ height: '100%' }} src={frontIdImg} alt="frontId" />
						</div>
					)}
					{backIdImg && isIdVerification && (
						<div
							className="col-11 justify_center align_center margin_top_20 margin_bottom_20"
							style={{ height: '260px' }}
						>
							<img style={{ height: '100%' }} src={backIdImg} alt="frontId" />
						</div>
					)}
					{accountVerifiedBy ? (
						<>
							<div className="align_center margin_bottom_8">
								<div className="label col-4">{t('usersAdministrations.verificationTime_Date') + ':'}</div>
								<div className="content col-8">
									<span className="date font_700">{date}</span>
									<span className="time font_700"> {time}</span>
								</div>
							</div>
							<div className="align_center margin_bottom_8">
								<div className="label col-4">{t('usersAdministrations.verificationOperator') + ':'}</div>
								<div className="content font_700 col-8">{accountVerifiedBy}</div>
							</div>
						</>
					) : (
						<div className="label col-12 align_center justify_center font_18px">{t('general.refreshPage')}</div>
					)}
				</div>
			}
			footer={
				<>
					<button className="btn md round outline toggle_modal_12" onClick={close}>
						{t('general.close')}
					</button>
				</>
			}
		/>
	);
};

export default DocumentsModal;
