import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import Modal from '../../../../../components/Modals';
import { useNotify } from '../../../../../hooks/useNotifications';

interface Props {
	id: number;
	setNoteModal: React.Dispatch<React.SetStateAction<boolean>>;
	getOperatorNotes: () => Promise<void>;
}

export const NoteModal: FC<Props> = ({ id, setNoteModal, getOperatorNotes }) => {
	const { t } = useTranslation();
	const { success, warn } = useNotify();

	const [disabled, setDisabled] = useState(false);
	const [description, setDescription] = useState('');

	const save = async () => {
		if (description.trim().length === 0) {
			warn(t('toast.warn.emptyField'));
			return;
		}
		setDisabled(true);
		try {
			const data = await api.users.createOperatorNote(id, description);
			getOperatorNotes();
			setNoteModal(false);
			success(t('toast.success.noteCreated'));
		} catch (err: any) {
			console.log(err);
			const errorText =
				err?.response?.data.detail?.[0]?.msg ||
				err?.response?.data?.detail ||
				err?.detail ||
				t('toast.error.defaultText');
		} finally {
			setDisabled(false);
		}
	};

	return (
		<Modal
			opened={true}
			size={'md'}
			align={'start'}
			direction={'column'}
			cancelAction={() => {
				setNoteModal(false);
			}}
			header={<h2 className="modal_title padding_left_20">{`${t('usersAdministrations.addNote')}`}</h2>}
			body={
				<div>
					<div className="col-12 align_center padding_top_30 flex_column padding_bottom_30">
						<div className="flex_direction_row col-8 margin_top_10 align_center">
							<textarea
								rows={12}
								className="col-12 disable_resize"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
						</div>
					</div>
				</div>
			}
			footer={
				<>
					{!disabled ? (
						<>
							<button className="btn md round outline" onClick={() => setNoteModal(false)}>
								{t('general.cancel')}
							</button>

							<button type="submit" className="btn md round green" onClick={save}>
								{t('general.save')}
							</button>
						</>
					) : (
						<button disabled={true} className={`btn md round green`} onClick={() => {}}>
							<i className="mdi mdi-rotate-right mdi-spin" />
						</button>
					)}
				</>
			}
		></Modal>
	);
};
