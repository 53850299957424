import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImagePreview } from '../../../../../components/Modals/ImagePreview';
import { VerificationData } from '../types';

interface Props {
	verificationData: VerificationData | null;
	isIdVerification: boolean;
}

interface fieldsTranslations {
	[key: string]: any;
}

export const VerificationTable: FC<Props> = ({ verificationData, isIdVerification }) => {
	const { t } = useTranslation();

	const [previewImage, setPreviewImage] = useState<string | null>(null);

	const fieldsTranslations: fieldsTranslations = {
		idImageData: isIdVerification ? t('usersAdministrations.idImage') : t('usersAdministrations.passportImage'),
		idBackImageData: t('usersAdministrations.idBackImage'),
		frontFacingImageData: t('usersAdministrations.frontFacingImage'),
	};

	const imageFields = ['idImageData', isIdVerification ? 'idBackImageData' : null, 'frontFacingImageData'];

	return (
		<div className="padding_10">
			<table className="admin_table sticky_header">
				<thead className="t_head">
					<tr>
						<th>{t('usersAdministrations.image')}</th>
						<th>{t('general.preview')}</th>
					</tr>
				</thead>
				<tbody className="t_body">
					{verificationData
						? Object.entries(verificationData)
								?.filter(([key, value]) => value != null && imageFields?.includes(key))
								?.map(([dataKey, value], index) => (
									<tr key={index}>
										<td>{fieldsTranslations[dataKey]}</td>
										<td>
											<span className="tooltip_left">
												<i className="mdi mdi-eye" onClick={() => setPreviewImage(value)}></i>
												<span className="tooltip_content">{`${t('general.preview')} ${
													fieldsTranslations[dataKey]
												}`}</span>
											</span>
										</td>
									</tr>
								))
						: null}
				</tbody>
			</table>
			{previewImage ? (
				<div>
					<ImagePreview url={previewImage} close={() => setPreviewImage(null)} className="no_background" />
				</div>
			) : null}
		</div>
	);
};
