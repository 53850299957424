import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
	name: string;
	label: string;
	onLabel: string;
	offLabel: string;
	editable: boolean;
	edit: boolean;
	onChange?: () => void;
}

export const SwitchFieldSmall: FC<Props> = ({ name, label, editable, edit, onChange }) => {
	const { register } = useFormContext();

	return (
		<div className={`form_row ${!(editable && edit) ? 'read_only' : ''} flex_center`}>
			<label htmlFor={name} className="input_label col-4 margin_right_4 font_11px">
				{label}
			</label>
			<label
				style={{ background: 'transparent' }}
				className={`col-8 switch on_off margin_top_12 margin_bottom_12 ${editable ? '' : ' disabled'} flex_center`}
				htmlFor={name}
			>
				<input
					className="switch_mark"
					id={name}
					name={name}
					type="checkbox"
					ref={register}
					disabled={!editable || !edit}
					onChange={onChange}
				/>
				<span className="slider"></span>
			</label>
		</div>
	);
};
