import React from 'react';
import ItemToggler from './ItemToggler';
import { Context, Item } from '../types';
import { Draggable, Droppable } from 'react-beautiful-dnd';

interface Props {
	index: number;
	context: Context;
	nested?: boolean;
	draggable: boolean;
	type?: string;
	generateContext: (path: string, item: Item, parent: Context) => Context;
	handleChange: (ctx: Context) => void;
}

export default function TreeItem(props: Props) {
	// Destructure properties from props
	const { index, context, nested = false, draggable, handleChange, generateContext, type } = props;

	// Destructure properties from context and item
	const { item, path, isExpanded } = context;
	const { items, sortable = false } = item;

	// Check is item nested inside of another item
	const levelClass = nested ? 'level_two_item' : 'level_one_item';

	// Create class for the subitem list based on the expanded value
	const listClass = isExpanded ? 'level_two_list expanded' : 'level_two_list';

	return (
		<Draggable key={path} draggableId={path} index={index}>
			{(draggableProvided, snapshot) => (
				<div
					ref={draggableProvided.innerRef}
					{...draggableProvided.draggableProps}
					className={snapshot.isDragging ? `${levelClass} dragging` : levelClass}
				>
					<ItemToggler
						context={context}
						handleChange={handleChange}
						draggable={draggable}
						dragHandleProps={draggableProvided.dragHandleProps}
					/>

					{/* Loop through the subitems if they are provided */}
					{items && isExpanded && (
						<Droppable droppableId={path} type={type || path}>
							{(droppable) => (
								<div ref={droppable.innerRef} {...droppable.droppableProps} className={listClass}>
									{items.map((item: Item, index: number) => {
										const ctx = generateContext(`${path}.${index}`, item, context);

										return (
											<TreeItem
												key={item.id}
												index={index}
												nested={true}
												context={ctx}
												draggable={sortable}
												handleChange={handleChange}
												generateContext={generateContext}
											/>
										);
									})}

									{/* Placeholder for reordering */}
									{sortable && droppable.placeholder}
								</div>
							)}
						</Droppable>
					)}
				</div>
			)}
		</Draggable>
	);
}
