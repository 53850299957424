import React from 'react';
import ItemToggler from './ItemToggler';
import { Context, Item } from '../types';
import { Draggable, Droppable } from 'react-beautiful-dnd';

interface Props {
	index: number;
	context: Context;
	nested?: boolean;
	generateContext: (path: string, item: Item, parent: Context) => Context;
	handleChange: (ctx: Context) => void;
}

export default function TreeItem(props: Props) {
	// Destructure properties from props
	const { index, context, nested = false, handleChange, generateContext } = props;

	// Destructure properties from context and item
	const { item, path, isExpanded } = context;
	const { items } = item;

	// Check is item nested inside of another item
	const levelClass = nested ? 'level_two_item' : 'level_one_item';

	// Create class for the subitem list based on the expanded value
	const listClass = isExpanded ? 'level_two_list expanded' : 'level_two_list';

	return (
		<div className={levelClass}>
			<ItemToggler context={context} handleChange={handleChange} levelClass={levelClass} />

			{/* Loop through the subitems if they are provided */}
			{items && isExpanded && (
				<div className={listClass}>
					{items.map((item: Item, index: number) => {
						const ctx = generateContext(`${path}.${index}`, item, context);

						return (
							<TreeItem
								key={item.id}
								index={index}
								nested={true}
								context={ctx}
								handleChange={handleChange}
								generateContext={generateContext}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
}
